const reject = (start_time, timeout) => Date.now() > start_time + timeout;

const untilAvailable = async (name) => {
  const retry_timing = 10;
  const timeout = 1000;
  const start_time = Date.now();

  while (!reject(start_time, timeout)) {
    if (window[name]) {
      return;
    }
    await new Promise(r => setTimeout(r, retry_timing)); // basically a `sleep`
  }

  throw new Error('Timeout');
};

export const getZendeskClient = async () => {
  await untilAvailable('ZAFClient');
  return ZAFClient.init();
};
